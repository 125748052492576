import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import { getImageUrl } from '@takeshape/routing';
import ReactPlayer from 'react-player/youtube';
import classNames from 'classnames';
import Popup from 'reactjs-popup';
import Glider from 'react-glider';
import 'glider-js/glider.min.css';

import Layout from '../components/Layout';
import StoryForm from '../components/forms/StoryForm';
import GymForm from '../components/forms/GymForm';

import useResize from '../hooks/useResize';
import { getYoutubeThumbnail } from '../utils/common';

import iconArrowRight from '../assets/images/icons/next-slide-arrow.svg';
import logoJJMark from '../assets/images/logos/JJMarkSpinner.gif';
import imageFindYourMatBg from '../assets/images/bg/find-your-mat-bg.png';
import iconPlay from '../assets/images/icons/play-btn.svg';

const IndexPage = ({
  data: {
    site: {
      siteMetadata: { siteUrl },
    },
    takeshape: {
      getLandingPage: { preFooter, seo },
      getHomePage: {
        heroSection,
        asFeaturedOn,
        fullEpisodes,
        shortStories,
        reviewSection,
        aboutKennyKim,
        whyMatMade,
        findYourMatSection,
        apparel,
        partners,
      },
    },
  },
}) => {
  const { isMobile, isTablet } = useResize();
  return (
    <Layout
      title={seo.title}
      description={seo.description}
      image={getImageUrl(seo.image.path)}
      url={siteUrl}
    >
      <SectionHero data={heroSection} />
      <SectionAsFeaturedOn data={asFeaturedOn} />
      <SectionFullEpisodes data={fullEpisodes} isMobile={isMobile} isTablet={isTablet} />
      <SectionShortStories data={shortStories} isMobile={isMobile} />
      <SectionReviews data={reviewSection} />
      <SectionAboutKennyKim data={aboutKennyKim} />
      <SectionWhyMatMade data={whyMatMade} />
      <SectionFindYourMat data={findYourMatSection} />
      <SectionApparel data={apparel} />
      <SectionPartners data={partners} />
      <SectionForms preFooter={preFooter} />
      {/* <SectionSecond biography={biography} />
      <ThirdSecond isMobile={isMobile} about={about} cta={cta} /> */}
    </Layout>
  );
};

function NextArrow(props) {
  const { onClick } = props;
  return (
    <div className="slick-next" onClick={onClick}>
      <img src={iconArrowRight} alt="icon" />
    </div>
  );
}

const SectionHero = ({
  data: { doNotDisplaySection, headlineStyle1, headlineStyle2, headlineStyle3, videoLink },
}) => {
  if (doNotDisplaySection) {
    return null;
  }
  return (
    <section className="bg-black pt-6 pb-20 overflow-x-hidden">
      <div className="container lg:px-5">
        <div className="flex flex-wrap gap-8 justify-center xl:justify-start">
          <h1 className="font-knockout uppercase inline-flex flex-col md:pt-16 text-center xl:text-left">
            <span className="h2 text-red">{headlineStyle1}</span>
            <span className="h1 text-white">{headlineStyle2}</span>
            <span
              className="h1 w-max mx-auto xl:ml-24 after:content-[''] after:mx-auto after:block after:w-[80%] after:h-[2px] after:bg-white after:opacity-50"
              style={{ WebkitTextStroke: '1px white' }}
            >
              {headlineStyle3}
            </span>
          </h1>
          <div className="relative">
            <ReactPlayer
              url={videoLink}
              light={getYoutubeThumbnail(videoLink)}
              playIcon={<img src={iconPlay} alt="youtube-play-icon" />}
              playing
              width="300px"
              height="550px"
            />
            <img
              className="absolute left-[50] md:right-[-110px] bottom-[-50px] max-w-[165px]"
              src={logoJJMark}
              alt="logo"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

const SectionAsFeaturedOn = ({ data: { doNotDisplaySection, headline, repeater } }) => {
  if (doNotDisplaySection) {
    return null;
  }
  return (
    <section className="bg-black py-12 lg:py-16">
      <div className="container lg:px-5">
        <h2 className="h6 font-knockout text-white text-center uppercase mb-8">{headline}</h2>
        <div className="md:grid md:grid-cols-12 md:gap-8">
          {repeater.map((item, index) => (
            <div
              className="md:col-span-6 lg:col-span-3 flex items-center justify-center"
              key={index}
            >
              {item?.url ? (
                <a href={item.url} target="_blank" rel="noopener noreferrer">
                  <img
                    src={getImageUrl(item.logo.path, {
                      'max-w': 250,
                      q: 50,
                      fit: 'crop',
                      fm: 'webp',
                    })}
                    alt={item.logo.title ?? 'image'}
                    loading="lazy"
                  />
                </a>
              ) : (
                <img
                  src={getImageUrl(item.logo.path, {
                    'max-w': 250,
                    q: 50,
                    fit: 'crop',
                    fm: 'webp',
                  })}
                  alt={item.logo.title ?? 'image'}
                  loading="lazy"
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const SectionFullEpisodes = ({
  data: { doNotDisplaySection, headlineLine1, headlineLine2, description, fullEpisodes },
  isMobile,
  isTablet,
}) => {
  if (doNotDisplaySection) {
    return null;
  }

  const getSlidesToShow = useMemo(() => {
    if (isMobile) {
      return 1;
    } else if (isTablet) {
      return 2;
    } else {
      return 4;
    }
  }, [isMobile, isTablet]);

  return (
    <section className="py-12 lg:py-16">
      <div className="container">
        <div className="max-w-xl mx-auto text-center">
          <h3 className="font-knockout uppercase flex flex-col">
            <span className="h5 mb-1 text-red">{headlineLine1}</span>
            <span className="h1 mb-6 after:content-[''] after:mx-auto after:block after:w-[40px] after:h-[2px] after:bg-red">
              {headlineLine2}
            </span>
          </h3>
          <p>{description}</p>
        </div>
        <div className="mt-6">
          <Glider hasDots draggable slidesToShow={getSlidesToShow} slidesToScroll={1}>
            {fullEpisodes.map((item, index) => (
              <div key={index} className="px-2 mx-auto">
                <ReactPlayer
                  url={item.videoLink}
                  light={getYoutubeThumbnail(item.videoLink, 'high')}
                  playIcon={<img src={iconPlay} alt="youtube-play-icon" />}
                  playing
                  width="100%"
                  height={181}
                />
                <h4 className="p-lg font-bold mt-4 mb-2">{item.videoTitle}</h4>
                <p className="font-light">{item.videoDescription}</p>
              </div>
            ))}
          </Glider>
        </div>
      </div>
    </section>
  );
};

const SectionShortStories = ({
  data: {
    doNotDisplaySection,
    headlineLine1,
    headlineLine2,
    description,
    shortStories,
    headlineBelowShortStories,
    ytIcon,
    ytIconUrl,
    descriptionBelowShortStories,
    buttonUrl,
    buttonText,
  },
  isMobile,
}) => {
  if (doNotDisplaySection) {
    return null;
  }
  return (
    <section className="py-12 lg:py-16 bg-black">
      <div className="container">
        <div className="max-w-xl mx-auto text-center">
          <h3 className="font-knockout uppercase flex flex-col">
            <span
              className="h1 mb-1 before:content-[''] before:mx-auto before:block before:w-[40px] before:h-[2px] before:bg-red before:mb-2"
              style={{ WebkitTextStroke: '1px white' }}
            >
              {headlineLine1}
            </span>
            <span className="h5 text-red mb-6">{headlineLine2}</span>
          </h3>
          <p className="text-white">{description}</p>
        </div>
        <div className="max-w-2xl mx-auto">
          {isMobile ? (
            <Glider hasDots draggable slidesToShow={1} slidesToScroll={1}>
              {shortStories.map((item, index) => (
                <div className="py-4 min-h-[375px]" key={index}>
                  <ReactPlayer
                    url={item.videoLink}
                    light={getYoutubeThumbnail(item.videoLink)}
                    playIcon={<img src={iconPlay} alt="youtube-play-icon" />}
                    playing
                    width="100%"
                    height="100%"
                  />
                </div>
              ))}
            </Glider>
          ) : (
            <div className="relative space-x-4 md:grid md:grid-cols-3">
              {shortStories.map((item, index) => (
                <div className={`${index === 1 ? 'py-4' : 'py-10'} min-h-[475px]`} key={index}>
                  <ReactPlayer
                    url={item.videoLink}
                    light={getYoutubeThumbnail(item.videoLink)}
                    playIcon={<img src={iconPlay} alt="youtube-play-icon" />}
                    playing
                    width="100%"
                    height="100%"
                  />
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="max-w-xl mx-auto text-center">
          <a
            className="flex flex-wrap items-center justify-center gap-x-3 mb-7 mt-12"
            href={ytIconUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="h5 font-knockout text-white uppercase">
              {headlineBelowShortStories}
            </span>
            <img
              src={getImageUrl(ytIcon.path, {
                'max-w': 47,
                q: 50,
                fit: 'crop',
                fm: 'webp',
              })}
              alt={ytIcon.title ?? 'image'}
              loading="lazy"
            />
          </a>
          <p className="text-white mb-7">{descriptionBelowShortStories}</p>
          <a
            href={buttonUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="rounded font-bold bg-red py-3 px-5 text-white uppercase"
          >
            {buttonText}
          </a>
        </div>
      </div>
    </section>
  );
};

const SectionReviews = ({
  data: { doNotDisplaySection, headlineLine1, headlineLine2, description, reviews },
}) => {
  if (doNotDisplaySection) {
    return null;
  }
  return (
    <section className="py-12 lg:py-16 bg-black-light">
      <div className="container">
        <div className="max-w-xl mx-auto text-center">
          <h3 className="font-knockout uppercase flex flex-col">
            <span className="h5 mb-2 text-red">{headlineLine1}</span>
            <span className="h3 mb-4 text-white">{headlineLine2}</span>
          </h3>
          <p className="text-white font-light">{description}</p>
        </div>
        <div className="max-w-5xl mx-auto gap-y-6 md:gap-y-10 lg:gap-y-0 lg:gap-x-12 flex flex-wrap justify-center mt-14">
          {reviews.map((item, index) => (
            <div key={index} className={classNames({ 'lg:mt-14': index % 2 })}>
              <img
                src={getImageUrl(item.reviewScreenshot.path, {
                  'max-w': 450,
                  q: 50,
                  fit: 'crop',
                  fm: 'webp',
                })}
                alt={item.reviewScreenshot.title ?? 'image'}
                loading="lazy"
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const SectionAboutKennyKim = ({
  data: { doNotDisplaySection, headline, description, imageLeft },
}) => {
  if (doNotDisplaySection) {
    return null;
  }
  return (
    <section className="py-12 lg:py-16">
      <div className="container">
        <div className="max-w-xl lg:max-w-none mx-auto">
          <div id="aboutkenny" className="lg:grid lg:grid-cols-12 lg:gap-8">
            <div className="lg:col-span-7">
              {imageLeft?.path && (
                <img
                  src={getImageUrl(imageLeft.path, {
                    'max-w': 635,
                    q: 50,
                    fit: 'crop',
                    fm: 'webp',
                  })}
                  alt={headline}
                  loading="lazy"
                />
              )}
            </div>
            <div className="lg:col-span-5 mt-5 lg:mt-0">
              <h3 className="h4 font-knockout mb-4 text-red uppercase">{headline}</h3>
              <div dangerouslySetInnerHTML={{ __html: description }} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const SectionWhyMatMade = ({
  data: { doNotDisplaySection, headline, description, imageRight },
}) => {
  if (doNotDisplaySection) {
    return null;
  }
  return (
    <section className="py-12 lg:py-16 bg-black-light">
      <div className="container">
        <div className="max-w-xl lg:max-w-none mx-auto">
          <div id="aboutshow" className="lg:grid lg:grid-cols-12 lg:gap-8">
            <div className="lg:col-span-5 mt-5 lg:mt-0">
              <div className="flex flex-col justify-center h-full">
                <h3 className="h4 font-knockout mb-4 text-white uppercase">{headline}</h3>
                <div className="text-white" dangerouslySetInnerHTML={{ __html: description }} />
              </div>
            </div>
            <div className="lg:col-span-7 mt-5 lg:mt-0">
              {imageRight?.path && (
                <img
                  className="lg:ml-auto"
                  src={getImageUrl(imageRight.path, {
                    'max-w': 600,
                    q: 50,
                    fit: 'crop',
                    fm: 'webp',
                  })}
                  alt={headline}
                  loading="lazy"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const SectionFindYourMat = ({
  data: { doNotDisplaySection, headline, description, topGymHeadline, gyms, buttonText, buttonUrl },
}) => {
  if (doNotDisplaySection) {
    return null;
  }

  return (
    <section className="bg-black">
      <div
        className="py-16 lg:py-24 bg-no-repeat bg-[length:auto_100%]"
        style={{
          backgroundImage: `url(${imageFindYourMatBg})`,
        }}
      >
        <div className="container">
          <div className="max-w-2xl mx-auto text-center text-white">
            <h3 className="font-knockout uppercase flex flex-col">
              <span className="h1 mb-1 after:content-[''] after:mx-auto after:block after:w-[40px] after:h-[2px] after:bg-red after:my-3">
                {headline}
              </span>
            </h3>
            <p className="font-light">{description}</p>
            {topGymHeadline && (
              <h4 className="h4 font-knockout uppercase mt-20">{topGymHeadline}</h4>
            )}
          </div>
          <div className="mx-auto mt-14 max-w-5xl">
            <div className="md:grid md:grid-cols-12 md:gap-8">
              {gyms.map((gym) => (
                <div key={gym._id} className="md:col-span-6 lg:col-span-4 mt-5 md:mt-0">
                  <div className="bg-white px-6 py-8">
                    {gym?.images?.[0]?.gymPhotos?.path && (
                      <img
                        className="w-full h-24 object-contain"
                        src={getImageUrl(gym.images[0].gymPhotos.path, {
                          'max-w': 270,
                          q: 50,
                          fm: 'webp',
                        })}
                        alt={gym?.gymName ?? 'gym'}
                        loading="lazy"
                      />
                    )}
                  </div>
                  <div className="bg-[#f0f0f0] text-center py-7 px-6">
                    {gym?.gymName && <h5 className="font-bold text-2xl">{gym.gymName}</h5>}
                    {gym?.address?.city?.cityName && (
                      <div className="p-lg mt-3">{gym.address.city.cityName}</div>
                    )}
                    <a
                      href="/"
                      className="rounded font-bold bg-white border border-red py-3 px-5 text-red uppercase inline-block mt-5"
                    >
                      Comming Soon
                    </a>
                  </div>
                </div>
              ))}
            </div>
            <div className="text-center mt-8">
              <a
                className="rounded font-bold bg-red py-3 px-7 text-white uppercase inline-block"
                href={buttonUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {buttonText}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const SectionApparel = ({ data: { doNotDisplaySection, headline, description, products } }) => {
  if (doNotDisplaySection) {
    return null;
  }
  return (
    <section className="py-12 lg:py-16 ">
      <div className="container">
        <div className="max-w-2xl mx-auto text-center">
          <h3 className="font-knockout uppercase flex flex-col">
            <span className="text-black h1 mb-1 after:content-[''] after:mx-auto after:block after:w-[40px] after:h-[2px] after:bg-red after:my-3">
              {headline}
            </span>
          </h3>
          <p className="text-black font-light">{description}</p>
        </div>
        <div className="mx-auto mt-14">
          <div className="md:grid md:grid-cols-12 md:gap-8">
            {products.map((item, index) => (
              <div key={index} className="md:col-span-6 lg:col-span-3 mt-5 md:mt-0">
                <a href={item.url} target="_blank" rel="noopener noreferrer" className="">
                  <img
                    className="w-full h-72 object-cover"
                    src={getImageUrl(item.image.path, {
                      'max-w': 450,
                      q: 50,
                      fm: 'webp',
                    })}
                    alt={item?.title ?? 'product'}
                    loading="lazy"
                  />
                  <h4 className="p-lg mt-4 mb-2">{item.title}</h4>
                  <div className="font-bold">${item.price} USD</div>
                </a>
              </div>
            ))}
          </div>
          <div className="text-center mt-8">
            <a
              className="rounded font-bold bg-black py-3 px-7 text-white uppercase inline-block"
              href="https://store.matmade.com/collections/all"
              target="_blank"
              rel="noopener noreferrer"
            >
              View All
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

const SectionPartners = ({ data: { doNotDisplaySection, headline, description, repeater2 } }) => {
  if (doNotDisplaySection) {
    return null;
  }
  return (
    <section className="py-12 lg:py-16 bg-black">
      <div className="container">
        <div className="max-w-2xl mx-auto text-center">
          <h3 className="font-knockout uppercase flex flex-col">
            <span
              className="h1 mb-1 after:content-[''] after:mx-auto after:block after:w-[40px] after:h-[2px] after:bg-red after:my-3"
              style={{ WebkitTextStroke: '1px white' }}
            >
              {headline}
            </span>
          </h3>
          <p className="text-white font-light">{description}</p>
        </div>
        <div className="max-w-5xl mx-auto mt-14">
          <div className="md:grid md:grid-cols-12 md:gap-8">
            {repeater2.map((item, index) => (
              <div key={index} className="md:col-span-6 mt-5 max-w-sm md:max-w-none mx-auto">
                <a
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center"
                >
                  <img
                    className="w-full h-[75px] object-contain"
                    src={getImageUrl(item.partnerLogo.path, {
                      'max-w': 450,
                      q: 50,
                      fm: 'webp',
                    })}
                    alt={item.partnerLogo.title ?? 'partner-logo'}
                    loading="lazy"
                  />
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

const SectionForms = ({ preFooter }) => {
  const renderTitle = (formName) => {
    if (formName === 'GymForm') {
      return 'Gym Form';
    }
    if (formName === 'StoryForm') {
      return 'Story Form';
    }
  };
  const renderForm = (formName, close) => {
    if (formName === 'GymForm') {
      return <GymForm close={close} />;
    }
    if (formName === 'StoryForm') {
      return <StoryForm close={close} />;
    }
  };

  return (
    <section className="section-fourth bg-black pb-20 pt-24">
      <div className="container">
        <div className="max-w-4xl mx-auto">
          <div className="grid grid-cols-12 md:gap-8">
            {preFooter?.ctAs?.map((item, index) => (
              <div className="col-span-12 lg:col-span-6 mt-8 lg:mt-0" key={index}>
                <div className="max-w-[395px] mx-auto">
                  <h3 className="h5 font-knockout uppercase text-red mb-3">{item.headline}</h3>
                  <p className="p-md text-white mb-3">{item.description}</p>
                  {item.button?.text && item.button?.form && item.button?.form !== 'none' ? (
                    <>
                      <Popup
                        trigger={
                          <button
                            className="rounded font-bold border border-white py-3 px-7 text-white uppercase inline-block mt-5"
                            type="button"
                          >
                            {item.button.text}
                          </button>
                        }
                        modal
                      >
                        {(close) => (
                          <div className="pt-8 px-4 overflow-auto max-h-[93vh] bg-white">
                            <button
                              className="cursor-pointer absolute block py-[2px] px-[5px] text-2xl leading-5 top-[-10px] right-[-10px] bg-white rounded-[18px] border border-[#cfcece]"
                              onClick={close}
                              type="button"
                            >
                              &times;
                            </button>
                            <div className="h5 font-knockout text-red uppercase text-center w-full">
                              {renderTitle(item.button.form)}
                            </div>
                            <div className="mt-4 py-8 px-1 w-full">
                              {renderForm(item.button.form, close)}
                            </div>
                          </div>
                        )}
                      </Popup>
                      <div style={{ display: 'none' }}>
                        <GymForm />
                        <StoryForm />
                      </div>
                    </>
                  ) : (
                    <a
                      href={item.button.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="rounded font-bold border border-white py-3 px-7 text-white uppercase inline-block mt-5"
                    >
                      {item.button.text}
                    </a>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
    takeshape {
      getHomePage {
        heroSection {
          doNotDisplaySection
          headlineStyle1
          headlineStyle2
          headlineStyle3
          videoLink
        }
        asFeaturedOn {
          doNotDisplaySection
          headline
          repeater {
            logo {
              title
              path
            }
            url
          }
        }
        fullEpisodes {
          doNotDisplaySection
          headlineLine1
          headlineLine2
          description
          fullEpisodes {
            videoTitle
            videoDescription
            videoLink
          }
        }
        shortStories {
          doNotDisplaySection
          headlineLine1
          headlineLine2
          description
          shortStories {
            videoLink
          }
          headlineBelowShortStories
          ytIcon {
            path
            title
          }
          ytIconUrl
          descriptionBelowShortStories
          buttonUrl
          buttonText
        }
        reviewSection {
          doNotDisplaySection
          headlineLine1
          headlineLine2
          description
          reviews {
            reviewScreenshot {
              path
              title
            }
          }
        }
        aboutKennyKim {
          doNotDisplaySection
          headline
          description(format: html)
          imageLeft {
            path
          }
        }
        whyMatMade {
          doNotDisplaySection
          headline
          description(format: html)
          imageRight {
            path
          }
        }
        findYourMatSection {
          buttonText
          buttonUrl
          gyms {
            _id
            address {
              city {
                cityName
              }
            }
            contactInfo {
              url
            }
            gymName
            images {
              gymPhotos {
                path
              }
            }
            seo {
              slug
            }
          }
          description
          doNotDisplaySection
          headline
          topGymHeadline
        }
        apparel {
          description
          doNotDisplaySection
          headline
          products {
            image {
              path
            }
            price
            title
            url
          }
        }
        partners {
          description
          doNotDisplaySection
          headline
          repeater2 {
            partnerLogo {
              path
              title
            }
            url
          }
        }
      }

      getLandingPage {
        preFooter {
          ctAs {
            button {
              text
              form
              url
            }
            description
            headline
          }
        }
        seo {
          description
          title
          image {
            path
          }
        }
      }
    }
  }
`;

export default IndexPage;
