import React, { useState } from 'react';

import { encode } from '../../utils/form';

const labelClassName = 'text-xl font-semibold w-full';
const inputClassName =
  'font-normal mb-4 border-b border-black-light normal-case w-full focus:outline-none';

const StoryForm = ({ close }) => {
  const [state, setState] = useState({});

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => close())
      .catch((error) => alert(error));
  };

  return (
    <div className="netlify-form">
      <form
        netlify
        name="story-form"
        method="POST"
        data-netlify="true"
        netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="form-name" value="story-form" />
        <p hidden>
          <label>
            Don’t fill this out: <input name="bot-field" onChange={handleChange} />
          </label>
        </p>
        <p>
          <label className={labelClassName}>
            What is your name?
            <input
              className={inputClassName}
              type="text"
              name="userName"
              required
              onChange={handleChange}
            />
          </label>
        </p>
        <p>
          <label className={labelClassName}>
            What city are you in?
            <input
              className={inputClassName}
              type="text"
              name="userCity"
              required
              onChange={handleChange}
            />
          </label>
        </p>
        <p>
          <label className={labelClassName}>
            Phone Number?
            <input
              className={inputClassName}
              type="tel"
              name="userPhone"
              required
              onChange={handleChange}
            />
          </label>
        </p>
        <p>
          <label className={labelClassName}>
            Name of Gym where you Train?
            <input
              className={inputClassName}
              type="text"
              name="nameOfGym"
              required
              onChange={handleChange}
            />
          </label>
        </p>
        <p>
          <label className={labelClassName}>
            Tell us a little bit about your story (3-5 sentences)
            <textarea
              className={inputClassName}
              name="message"
              rows="1"
              required
              onChange={handleChange}
            />
          </label>
        </p>
        <p className="text-center">
          <button className="rounded font-bold bg-red py-3 px-7 text-white uppercase" type="submit">
            Submit
          </button>
        </p>
      </form>
    </div>
  );
};

export default StoryForm;
